<template>
  <div >
      <!-- 个人信息界面 -->
      <h4>个人信息:</h4>
      <el-descriptions class="margin-top"   :data="userinfo" :column="2"  border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          用户名:
        </template>
        {{ userinfo.userid }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          性别：
        </template>
        {{ genderLabel }}
      </el-descriptions-item>
      <el-descriptions-item ref="ageinfo">
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          专业：
        </template>
        {{ userinfo.specialty}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          出生日期：
        </template>
        {{ userinfo.age }}
      </el-descriptions-item>
      </el-descriptions>
      <el-button style="float: right; margin: 10px;" type="primary" @click="openchangeDialog">修改</el-button>
      <changeuserinfo
      :userinfo="userinfo"
      :dialogVisible="dialogVisible"
      @changeUserinfo="changeUserinfo"
      @close="dialogVisible=false"></changeuserinfo>
  </div>
</template>

<script>
import changeuserinfo from './changeuserinfo.vue'
import { getUserinfo } from '@/api/user'
import { getUserId } from '@/utils/storage'
export default {
  name: 'UserInfoView',
  data () {
    return {
      userid: this.Userid ? this.Userid : getUserId(), // 判断 Userid 是否存在
      userinfo: {},
      dialogVisible: false
    }
  },
  props: {
    Userid: {
      type: String,
      default: ''
    }
  },
  components: {
    changeuserinfo
  },
  methods: {
    openchangeDialog () {
      this.dialogVisible = true
    },
    changeUserinfo (res) {
      this.userinfo = {
        ...this.userinfo, // 保留原有的 userinfo
        sex: res.data.data.sex, // 更新性别
        specialty: res.data.data.specialty, // 更新专业
        age: res.data.data.age
      }
    },
    async loadUserInfo (userid) {
      try {
        const res = await getUserinfo(userid)
        this.userinfo = res.data.data || {} // 更新用户信息
      } catch (error) {
        console.error('获取用户信息失败:', error)
      }
    }
  },
  computed: {
    genderLabel () {
      return this.userinfo.sex === 0 ? '男' : '女'
    }
  },
  watch: {
    Userid: {
      immediate: true, // 立即执行
      handler (newVal) {
        if (newVal) {
          this.loadUserInfo(newVal) // 当 Userid 变化时加载用户信息
        }
      }
    }
  },
  async mounted () {
    const res = await getUserinfo(this.userid)
    this.userinfo = res.data.data
  }
}
</script>

<style>

</style>
