<template >
  <div >
  <el-dialog :before-close="close" :title="isEdit ? '修改规划' : '新增规划'" :visible="dialogVisible" width="80%" append-to-body >
      <span>
        <div class="changePlan" v-if="isEdit &&selectedRow">
          <p>学期：<selectview ref="semesterSelect" :msg="XQ" :value2="semester ? Number(semester) : null"  style="margin-left: 28px;"></selectview>
          </p><p>规划类型：<selectview ref="plantypeSelect" :msg="LX" :value2="plantype ? Number(plantype) : null"></selectview>
          </p>
          <p class="sty">规划标题：<el-input  placeholder="请输入内容" v-model="title" style="width: 50%;">
          </el-input></p>
          <p>
          规划内容：
          <el-input  type="textarea" :rows="5" placeholder="请输入内容" v-model="project">
          </el-input></p>
        </div>
        <div v-else>
          <p>学期：<selectview ref="semesterSelect" :msg="XQ"  style="margin-left: 28px;"></selectview>
          </p><p>规划类型：<selectview ref="plantypeSelect" :msg="LX" ></selectview>
          </p><p>规划标题：<el-input placeholder="请输入内容" v-model="title2" style="width: 50%;">
          </el-input></p>
          <p>
          规划内容：
          <el-input  type="textarea" :rows="5" placeholder="请输入内容" v-model="project2">
          </el-input></p>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="changePlan">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import selectview from '@/components/select'
import { updatePlan, addPlan } from '@/api/guihua'
import { getUserId } from '@/utils/storage'
export default {
  data () {
    return {
      userid: getUserId(),
      semester: '',
      plantype: '',
      title: '',
      title2: '',
      project: '',
      project2: '',
      value: false,
      XQ: [{ value: 1, label: '第一学期' },
        { value: 2, label: '第二学期' },
        { value: 3, label: '第三学期' },
        { value: 4, label: '第四学期' }],
      LX: [{ value: 1, label: '升学' },
        { value: 2, label: '考公，考编' },
        { value: 3, label: '专业对口工作' },
        { value: 4, label: '非本专业类型工作' },
        { value: 5, label: '创业' },
        { value: 6, label: '无业' }]
    }
  },
  props: {
    dialogVisible: { // 这是用于 v-model 绑定的 prop
      type: Boolean,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    selectedRow: {
      type: Object,
      default: () => ({}) // 默认值为一个空对象
    }
  },
  components: {
    selectview
  },
  methods: {
    close () {
      this.$emit('close', false) // 触发父组件的更新
    },
    // 修改或新增规划
    async changePlan () {
      if (this.isEdit) {
        console.log(this.selectedRow)
        const id = Number(this.selectedRow.id) // 获取规划id
        const userid = this.selectedRow.userid // 获取用户id
        const title = this.title // 获取规划标题
        const project = this.project // 获取规划内容
        const semester = String(this.$refs.semesterSelect.value) // 获取学期的选择值
        const plantype = String(this.$refs.plantypeSelect.value) // 获取规划类型的选择值
        const testBook = JSON.stringify({
          id,
          userid,
          title,
          project,
          semester,
          plantype
        })
        const res = await updatePlan(testBook)
        this.$emit('updateTable', res)// 触发父组件更新
        this.$emit('adminupdata', res)
        // 此处通过id把返回的规划更新到tableData中
        this.$emit('close', false)
      } else {
        const userid = this.userid // 获取用户id
        const title = this.title2 // 获取规划标题
        const project = this.project2 // 获取规划内容
        const semester = String(this.$refs.semesterSelect.value) // 获取学期的选择值
        const plantype = String(this.$refs.plantypeSelect.value) // 获取规划类型的选择值
        const testBook = JSON.stringify({
          userid,
          title,
          project,
          semester,
          plantype
        })
        const res = await addPlan(testBook)
        this.$emit('addTable', res) // 触发父组件更新
        this.$emit('close', false)
      }
    }
  },
  watch: {
    selectedRow: {
      immediate: true, // 在组件挂载时立即执行
      handler (newVal) {
        if (newVal) {
          this.title = newVal.title || ''
          this.project = newVal.project || ''
          this.semester = newVal.semester || ''
          this.plantype = newVal.plantype || ''
        }
      }
    }
  }
}
</script>

<style scoped>
.sty{
  margin-top: 5px;
}
</style>
