<template>
  <!-- 修改个人信息弹窗 -->
  <el-dialog  title="修改个人信息" :visible="dialogVisible"  width="60%" append-to-body >
        <div >
          <p>
          用户名:<el-button style="margin-left: 30px;" >{{ userinfo.userid }}</el-button>    </p><p>
          专 业：<el-input  placeholder="请输入内容" v-model="specialty" style="width: 150px; margin-left: 29px;"></el-input>
        </p><p>性 别：
          <el-radio style="margin-left: 24px;" v-model="radio" label="0">男</el-radio>
          <el-radio v-model="radio" label="1">女</el-radio>
        </p>
            <div class="block">
          出生日期：
              <el-date-picker v-model="value" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="changeuserinfo">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
import { updataUserinfo } from '@/api/user'
// import { getUserId } from '@/utils/storage'
export default {
  name: 'changeUserinfo',
  data () {
    return {
      /* userid: getUserId(), */
      radio: '0',
      value: '',
      specialty: ''
    }
  },
  props: {
    dialogVisible: { // 这是用于 v-model 绑定的 prop
      type: Boolean,
      required: true
    },
    userinfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close', false) // 触发父组件的更新
    },
    async changeuserinfo () {
      const user = {
        userid: this.userinfo.userid,
        specialty: this.specialty,
        sex: this.radio,
        age: this.value
      }
      const res = await updataUserinfo(user)
      this.$emit('changeUserinfo', res) // 触发父组件更新
      this.$emit('close', false)
    }
  },
  watch: {
    userinfo: {
      immediate: true, // 在组件挂载时立即执行
      handler (newVal) {
        if (newVal) {
          this.specialty = newVal.specialty || ''
          this.radio = String(newVal.sex) || ''
          this.value = newVal.age
        }
      }
    }
  }
}
</script>

<style>

</style>
