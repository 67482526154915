const INFO_KEY = 'login_info'
export const getInfo = () => {
  const defaultObj = { token: '', userId: '' }
  const result = localStorage.getItem(INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}

// 设置个人信息
export const setInfo = (obj) => {
  localStorage.setItem(INFO_KEY, JSON.stringify(obj))
}

// 拿到userid
export const getUserId = () => {
  const result = localStorage.getItem(INFO_KEY)
  const parsedResult = JSON.parse(result) // 解析字符串
  // console.log(parsedResult.data.userid)
  return parsedResult.data.userid // 访问 data.userid
}
// 拿到token
export const getToken = () => {
  const result = localStorage.getItem(INFO_KEY)
  const parsedResult = JSON.parse(result) // 解析字符串
  return parsedResult.data.token // 访问 data.userid
}
