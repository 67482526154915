<template>
  <div ><!-- 爱好信息界面 -->
      <h4>爱好:</h4>
      <el-table  :data="hobbyinfo" style="width: 100%" border>
        <el-table-column label="爱好" width="auto" prop="hobby">
        </el-table-column>
        <el-table-column label="爱好补充" width="800" prop="other">
        </el-table-column>
        <el-table-column label="操作" width="auto">
          <template slot-scope="scope">
            <el-button @click="handlechangdehobby(scope.row)" type="primary" size="small">修改</el-button>
            <el-button @click="handledelete(scope.row)" type="primary" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button style="float: right; margin: 10px;" type="primary" @click="openaddDialog">增加爱好</el-button>
      <changeHobby
      :dialogVisible="dialogVisible"
      :isEdit="isEdit"
      :selectedRow="selectedRow"
      :hobbyList="HobbyList"
      @close="dialogVisible = false"
      @addHobby="addhobby"
      @changeHobby="changehobby"></changeHobby>

  </div>
</template>

<script>
import changeHobby from '@/components/changeHobby.vue'
import { getHobby, getHobbyList, deleteHobby } from '@/api/user.js'
import { getUserId } from '@/utils/storage'
export default {
  name: 'hobbyView',
  data () {
    return {
      userid: getUserId(),
      hobbyinfo: [],
      HobbyList: [],
      selectedRow: null,
      isEdit: false,
      dialogVisible: false
    }
  },
  components: {
    changeHobby
  },
  methods: {
  // 打开添加弹窗
    openaddDialog () {
      this.dialogVisible = true
      this.isEdit = false
    },
    // 打开修改弹窗并传递数据
    handlechangdehobby (row) {
      this.dialogVisible = true
      this.isEdit = true
      this.selectedRow = row
      const matchedHobby = this.HobbyList.find(item => item.label === row.hobby)
      if (matchedHobby) { // 如果找到匹配项，将匹配项的 value 赋值给 selectedRow 的 value
        this.selectedRow.value = matchedHobby.value // 新增属性 value，赋值为匹配的 value
      }
    },
    // 接受添加爱好返回的数据并更新数据
    addhobby (res) {
      const matchedHobby = this.HobbyList.find(item => item.value === res.data.data.idhobby)
      if (res) {
        this.hobbyinfo.push({
          id: res.data.data.id,
          hobby: matchedHobby.label,
          other: res.data.data.other
        })
      }
    },
    // 接受修改爱好返回的数据并更新数据
    changehobby (res) {
      const index = this.hobbyinfo.findIndex(item => item.id === res.data.data.id) // 根据 id 找到对应下标
      if (index !== -1) { // 如果找到了对应的项
        this.$set(this.hobbyinfo, index, {
          ...this.hobbyinfo[index],
          idhobby: res.data.data.idhobby,
          other: res.data.data.other
        })
      }
    },
    // 删除爱好
    async handledelete (row) {
      this.selectedRow = row
      const id = this.selectedRow.id
      const res = await deleteHobby(id)
      if (res.data.code === 200) {
        const index = this.hobbyinfo.findIndex(item => item.id === id) // 根据 id 找到对应下标
        this.hobbyinfo.splice(index, 1) // 根据下标删除项
      }
      alert('删除成功')
    }

  },
  async mounted () {
    const res = await getHobby(this.userid)
    const res2 = await getHobbyList()
    this.hobbyinfo = res.data.data
    this.HobbyList = res2.data.data.map(item => {
      return {
        value: item.id, // 修改为实际的 ID 字段
        label: item.hobbyId // 修改为实际的名称字段
      }
    })
  }
}
</script>

<style scoped>

</style>
