<template>
  <!-- 修改密码界面 -->
  <div>
      <h4>修改密码：</h4>
      <p>原密码：<el-input type="password" placeholder="请输入原密码" v-model="oldpwd" style="width: 275px; margin-left: 18px;"></el-input></p>
      <p> 新密码：<el-input type="password" placeholder="请输入新密码" v-model="newpwd" style="width: 275px; margin-left: 18px;"></el-input></p>
      <p>确认密码：<el-input type="password" placeholder="请确认密码" v-model="cnpwd"  style="width: 275px;"></el-input></p>
      <el-button type="primary" @click="changepwd" style="margin-left: 283px;">确认</el-button>
      </div>
  </template>

<script>
import { changePassword } from '@/api/user'
export default {
  name: 'pwdView',
  data () {
    return {
      oldpwd: '',
      newpwd: '',
      cnpwd: ''
    }
  },
  methods: {
    // 修改密码
    async changepwd () {
      if (this.oldpwd === '' || this.newpwd === '' || this.cnpwd === '') {
        alert('请输全密码')
      } else if (this.newpwd !== this.cnpwd) {
        alert('两次密码不一致')
      } else {
        const userid = this.userid
        const old_password = this.oldpwd
        const new_password = this.newpwd
        const confirm_password = this.cnpwd
        const res = await changePassword(userid, old_password, new_password, confirm_password)
        if (res.data.code === 200) {
          alert('修改成功')
          this.oldpwd = ''
          this.newpwd = ''
          this.cnpwd = ''
          localStorage.removeItem('login_info')
          this.$router.push('/login')
        }
      }
    }
  }
}
</script>

  <style>

  </style>
