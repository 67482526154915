<template>
    <div class="app">
        <h2 class="title">学生生涯规划系统</h2>
        <el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
            background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" >
            <el-menu-item index="/home">首页</el-menu-item>
            <el-menu-item index="/guihua">自我规划</el-menu-item>
            <el-menu-item index="/userView">个人中心</el-menu-item>
        </el-menu>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>

</template>

<script>
export default {
  name: 'userView',
  data () {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    }
  },
  computed: {
    isStudent () {
      return this.$route.query.key === '1'
    }
  }
}
</script>

<style  scoped>

</style>
