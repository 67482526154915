<template>
  <!-- 添加爱好弹窗 -->
  <el-dialog :before-close="close" :title="isEdit ? '修改爱好' : '新增爱好'" :visible="dialogVisible"  width="60%"  >
      <span>
        <div v-if="isEdit &&selectedRow">
          <p> 爱好：<selectview ref="hobbychange" :msg="hobbyList" :value2="selectedRow.value"></selectview>
        </p><p>
          爱好补充：
          <el-input placeholder="请输入内容" v-model="other"></el-input>
        </p>
        </div>
        <div v-else>
          <p>
            爱好：<selectview ref="addhobby" :msg="hobbyList" ></selectview>
          </p><p>
            爱好补充：
            <el-input placeholder="请输入内容" v-model="other2"></el-input>
          </p>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="handlerhobby">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
import { updataHobby, addhobby } from '@/api/user'
import selectview from '@/components/select'
import { getUserId } from '@/utils/storage'
export default {
  name: 'changeHobby',
  data () {
    return {
      userid: getUserId(),
      other: '',
      other2: ''
    }
  },
  props: {
    dialogVisible: { // 这是用于 v-model 绑定的 prop
      type: Boolean,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    selectedRow: {
      type: Object,
      default: () => ({}) // 默认值为一个空对象
    },
    hobbyList: {
      type: Array,
      default: () => [] // 默认值为一个空数组
    }
  },
  components: {
    selectview
  },
  methods: {
    close () {
      this.$emit('close', false) // 触发父组件的更新
    },
    async handlerhobby () {
      if (this.isEdit) {
        const id = this.selectedRow.id
        const userid = this.userid
        const other = this.other
        const idhobby = this.$refs.hobbychange.value
        console.log(idhobby)
        const hobby = {
          id,
          userid,
          idhobby,
          other
        }
        if (other === '' || idhobby === '') {
          alert('请填写完整信息')
        } else {
          const res = await updataHobby(hobby)
          if (res.code === 200) {
            alert('修改成功')
          }
          this.$emit('changeHobby', res)
          this.close('close', false)
        }
      } else {
        const userid = this.userid
        const other = this.other2
        const idhobby = this.$refs.addhobby.value
        const hobby = {
          userid,
          idhobby,
          other
        }
        if (other === '' || idhobby === '') {
          alert('请填写完整信息')
        } else {
          const res = await addhobby(hobby)
          if (res.code === 200) {
            alert('添加成功')
          }
          this.$emit('addHobby', res)
          this.close('close', false)
        }
      }
    }
  },
  watch: {
    selectedRow: {
      immediate: true, // 在组件挂载时立即执行
      handler (newVal) {
        if (newVal) {
          this.other = newVal.other || ''
          this.hobby = newVal.hobby || ''
          this.value = newVal.value || ''
          this.id = newVal.id || ''
        }
      }
    }
  }
}
</script>

<style>

</style>
