<template>
  <!-- 修改家庭信息弹窗 -->
  <el-dialog :before-close="close" title="修改家庭信息" :visible="dialogVisible"  width="60%"  >
        <div >
          <p style="margin-left: 28px;">
          关系：<el-input placeholder="请输入内容" v-model="relation" style="width: 150px;"></el-input>
          </p>
          <p style="margin-left: 28px;">
          薪资：<el-input placeholder="请输入内容" v-model="salary" style="width: 150px;"></el-input></p>
          <p>家长工作：<selectview ref="parentwork" :msg="WL" :value2="parentinfo.parentwork" style="width: 150px;"></selectview></p>
          <p>家长学历：<selectview ref="parentedu" :msg="XL" :value2="parentinfo.parentedu" style="width: 150px;"></selectview ></p>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="changefamilyinfo">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
import selectview from '@/components/select'
import { updataParent } from '@/api/user'
import { getUserId } from '@/utils/storage'
export default {
  name: 'changeFamily',
  data () {
    return {
      userid: getUserId(),
      relation: '',
      salary: ''
    }
  },
  props: {
    dialogVisible: { // 这是用于 v-model 绑定的 prop
      type: Boolean,
      required: true
    },
    parentinfo: {
      type: Object,
      default: () => ({}) // 默认值为一个空对象
    },
    WL: {
      type: Array,
      default: () => [] // 默认值为一个空数组
    },
    XL: {
      type: Array,
      default: () => [] // 默认值为一个空数组
    }
  },
  components: {
    selectview
  },
  methods: {
    close () {
      this.$emit('close', false) // 触发父组件的更新
    },
    async changefamilyinfo () {
      const id = this.parentinfo.id
      const userid = this.userid
      const relation = this.relation
      const economy = this.salary
      const parentwork = this.$refs.parentwork.value
      const parentedu = this.$refs.parentedu.value
      const family = {
        id,
        userid,
        relation,
        economy,
        parentwork,
        parentedu
      }
      const res = await updataParent(family)
      this.$emit('close', false) // 触发父组件的更新
      this.$emit('changefamilyinfo', res)
    }
  },
  watch: {
    parentinfo: {
      immediate: true, // 在组件挂载时立即执行
      handler (newVal) {
        if (newVal) {
          this.relation = newVal.relation || ''
          this.salary = newVal.economy || ''
        }
      }
    }
  }
}
</script>

<style>

</style>
