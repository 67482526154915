//下拉框组件
<template>
    <el-select v-model="value" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
    </el-select>
</template>

<script>
export default {
  name: 'selectView',
  data () {
    return {
      options: [],
      value: null
    }
  },
  props: {
    msg: {
      type: Array,
      default: () => []
    },
    value2: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.options = this.msg // 更新选项
    this.initializeSelect() // 初始化
  },
  watch: {
    value2: function () {
      this.initializeSelect() // 监听 value2 变化
    }
  },
  methods: {
    initializeSelect () {
      this.value = this.value2 !== 0 ? this.value2 : null // 更新选中值
    }
  }
}
</script>

<style>

</style>
