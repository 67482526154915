<template>
  <div>
    <!-- 家庭信息界面 -->
    <h4>家庭信息:</h4>
    <el-descriptions class="margin-top"  :column="2" :data="parentinfo" border>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        关系：
      </template>
      {{ parentinfo.relation }}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-mobile-phone"></i>
       薪资：
      </template>
      {{ parentinfo.economy }}元
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-location-outline"></i>
        家长学历：
      </template>
      {{ parentEducationLabel}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-tickets"></i>
        家长工作类型：
      </template>
      {{ parentWorkLabel }}
    </el-descriptions-item>
    </el-descriptions>
    <el-button  style="float: right; margin: 10px;" type="primary" @click="dialogVisible = true">修改</el-button>
    <changeFamily
    :dialogVisible="dialogVisible"
    :parentinfo="parentinfo"
    :WL="WL"
    :XL="XL"
    @changefamilyinfo="changefamilyinfo"
    @close="dialogVisible = false"
    ></changeFamily>
  </div>
</template>

<script>
import changeFamily from './changeFamily.vue'
import { getParent } from '@/api/user'
import { getUserId } from '@/utils/storage'
export default {
  name: 'familyView',
  data () {
    return {
      userid: getUserId(),
      dialogVisible: false,
      parentinfo: {},
      WL: [{ value: 1, label: '医疗行业' },
        { value: 2, label: '教育行业' },
        { value: 3, label: '金融行业' },
        { value: 4, label: '销售行业' },
        { value: 5, label: '艺术行业' },
        { value: 6, label: '法律行业' },
        { value: 7, label: '人力行业' },
        { value: 8, label: '科研行业' },
        { value: 9, label: '建筑行业' },
        { value: 10, label: '服务行业' },
        { value: 11, label: '传媒行业' },
        { value: 12, label: 'IT行业' },
        { value: 13, label: '其它行业' },
        { value: 14, label: '无业' }],
      XL: [{ value: 1, label: '小学' },
        { value: 2, label: '初中' },
        { value: 3, label: '高中' },
        { value: 4, label: '中专' },
        { value: 5, label: '大专' },
        { value: 6, label: '本科' },
        { value: 7, label: '研究生' },
        { value: 8, label: '博士' }]
    }
  },
  components: {
    changeFamily
  },
  computed: {
    parentEducationLabel () {
      const edu = this.XL.find(item => item.value === this.parentinfo.parentedu)
      return edu ? edu.label : '未知学历'
    },
    parentWorkLabel () {
      const work = this.WL.find(item => item.value === this.parentinfo.parentwork)
      return work ? work.label : '未知工作类型'
    }
  },
  methods: {
    changefamilyinfo (res) {
      this.parentinfo = {
        ...this.parentinfo, // 保留原有的 parentinfo
        relation: res.data.data.relation, // 更新关系
        economy: res.data.data.economy, // 更新经济状况
        parentwork: res.data.data.parentwork, // 更新工作
        parentedu: res.data.data.parentedu // 更新教育水平
      }
    }
  },
  async mounted () {
    const res = await getParent(this.userid)
    this.parentinfo = res.data.data
  }
}
</script>

<style>

</style>
