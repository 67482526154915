import { getToken } from '@/utils/storage'
import request from '../utils/request'

// 查看
export const plan = (userid) => {
  const token = getToken() // 获取 Token
  return request.get('/TestBook', {
    params: {
      userid: userid
    },
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}
// 更新
export const updatePlan = (testBook) => {
  const token = getToken() // 获取 Token
  return request.put('/updateTestBook', testBook, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}` // 设置 Authorization 头
    }
  })
}
// 新增
export const addPlan = (testBook) => {
  const token = getToken() // 获取 Token
  return request.post('/addTestBook', testBook, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}` // 设置 Authorization 头
    }
  })
}
