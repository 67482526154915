<template>
  <!-- 规划界面 -->
    <div class="container">
      <el-button class="righbtn" type="primary" @click="openaddDialog">添加生涯规划</el-button>
      <el-table :data="tableData" style="width: 100%" >
        <el-table-column prop="createdAt"  label="创建时间"  width="auto"></el-table-column>
        <el-table-column prop="updatedAt"  label="更新时间"  width="auto"></el-table-column>
        <el-table-column prop="title"  label="规划标题"  width="auto"></el-table-column>
        <el-table-column prop="project"  label="规划内容" width="500"></el-table-column>
        <el-table-column label="操作" width="auto">
          <template slot-scope="scope">
            <el-button  @click="openchangeDialog(scope.row)" type="primary" size="small">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <changePlan
      :dialogVisible="dialogVisible"
      :selectedRow="selectedRow"
      :isEdit="isEdit"
      @close="dialogVisible = false"
      @updateTable="updatainfo"
      @addTable="addinfo"></changePlan>

    </div>
  </template>

<script>
import changePlan from '@/components/changePlan'

import { plan } from '@/api/guihua'
import { getUserId } from '@/utils/storage'
export default {
  name: 'guiHua',
  data () {
    return {
      tableData: [],
      userid: getUserId(),
      dialogVisible: false,
      isEdit: false,
      selectedRow: null
    }
  },
  components: {
    changePlan
  },
  methods: {
    // 时间格式化
    formatDate (dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const date = new Date(dateString)
      return date.toLocaleDateString('zh-CN', options) // 使用中文格式
    },
    // 添加规划
    openaddDialog () {
      this.isEdit = false
      this.dialogVisible = true
    },
    // 修改规划
    openchangeDialog (row) {
      this.isEdit = true
      this.dialogVisible = true
      this.selectedRow = row // 将当前行数据赋值给 selectedRow
    },
    // 接受子组件传的res数据更新tableData
    updatainfo (res) {
      const index = this.tableData.findIndex(item => item.id === res.data.data.id)
      if (index !== -1) {
        const updatedData = {
          ...this.tableData[index], // 保留原有的其他属性
          title: res.data.data.title, // 更新标题
          project: res.data.data.project, // 更新内容
          semester: Number(res.data.data.semester), // 转化为 Number 类型
          plantype: Number(res.data.data.plantype), // 转化为 Number 类型
          updatedAt: this.formatDate(new Date()) // 使用当前日期时间来更新
        }
        this.$set(this.tableData, index, updatedData)
      }
    },
    // 接受子组件传的res数据添加tableData
    addinfo (res) {
      if (res) {
        this.tableData.push(res.data.data)
      }
    }
  },
  async mounted  () {
    try {
      const res = await plan(this.userid)
      this.tableData = res.data.data.map(item => {
        return {
          ...item,
          createdAt: this.formatDate(item.createdAt),
          updatedAt: this.formatDate(item.updatedAt)
        }
      })
    } catch (err) {
      console.error(err)
    }
  }
}
</script>
  <style scoped >
    .container{
    margin: 10px;
  }
    .righbtn
  {
    margin-right: 10px;
  }
  </style>
