import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/user/home.vue'
import Login from '@/views/login'
import User from '@/views/user'
import Admin from '@/views/admin'
import guihua from '@/views/user/guihua'
import student from '@/views/admin/studentView'
import plan from '@/views/admin/planView'
import admininfo from '@/views/admin/adminView'
import user from '@/views/user/user'
import { getToken } from '@/utils/storage'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
      path: '/user',
      component: User,
      redirect: '/home',
      children: [
        { path: '/home', component: home },
        { path: '/guihua', component: guihua },
        { path: '/userView', component: user }
      ]
    },
    {
      path: '/admin',
      component: Admin,
      redirect: '/student',
      children: [
        { path: '/student', component: student },
        { path: '/plan', component: plan },
        { path: '/admininfo', component: admininfo }
      ]
    }
  ]
})

const authUrls = ['/home', '/guihua', '/userView', '/student', '/plan', '/admininfo']
// 路由守卫
router.beforeEach((to, from, next) => {
  // console.log(to, from, next)
  // 看 to.path 是否在 authUrls 中出现过
  if (!authUrls.includes(to.path)) {
    // 非权限页面，直接放行
    next()
    return
  }

  // 是权限页面，需要判断token
  const token = getToken()
  if (token) {
    next()
  } else {
    next('/login')
  }
})
export default router
