import Vue from 'vue'
import { pagination, MenuItemGroup, descriptions, DescriptionsItem, DatePicker, radio, MessageBox, dialog, option, select, container, main, aside, Table, TableColumn, tag, CarouselItem, carousel, Submenu, MenuItem, Button, Input, header, menu } from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(header)
Vue.use(menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(carousel)
Vue.use(CarouselItem)
Vue.use(tag)
Vue.use(TableColumn)
Vue.use(Table)
Vue.use(aside)
Vue.use(main)
Vue.use(container)
Vue.use(select)
Vue.use(option)
Vue.use(dialog)
Vue.use(radio)
Vue.use(DatePicker)
Vue.use(DescriptionsItem)
Vue.use(descriptions)
Vue.use(MenuItemGroup)
Vue.use(pagination)

Vue.prototype.$confirm = MessageBox.confirm
