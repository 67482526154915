<template>
  <div style="margin: 10px;">
  <userinfoView />
  <hobbyView class="juli"/>
  <familyView class="juli"/>
  <pwdView class="juli"/>
  <el-button type="primary" @click="edit" style="float: right; margin-bottom: 10px;">退出登录</el-button>
  </div>
</template>

<script>
import userinfoView from '../../components/userinfoView.vue'
import hobbyView from '../../components/hobbyView.vue'
import familyView from '../../components/familyView.vue'
import pwdView from '../../components/pwdView.vue'
export default {
  name: 'userView',
  components: {
    userinfoView,
    hobbyView,
    familyView,
    pwdView
  },
  methods: {
    edit () {
      localStorage.removeItem('login_info')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.juli{
  margin-top: 60px;
}

</style>
