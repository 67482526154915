<template>
  <lunbo></lunbo>
</template>

<script>
import lunbo from '@/components/lunbo'

export default {
  name: 'app',
  components: {
    lunbo
  }
}
</script>

<style scoped></style>
