import request from '../utils/request'
import { getToken } from '@/utils/storage'
/* eslint-disable camelcase */
// 查看个人信息
export const getUserinfo = (userid) => {
  const token = getToken() // 获取 Token
  return request.get('/user', {
    params: {
      userid: userid
    },
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}

// 修改个人信息
export const updataUserinfo = (user) => {
  const token = getToken() // 获取 Token
  console.log(user)
  return request.put('/updateuser', user, {
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}

// 查看家庭信息
export const getParent = (userid) => {
  const token = getToken() // 获取 Token
  return request.get('/family', {
    params: {
      userid: userid
    },
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}

// 修改家庭信息
export const updataParent = (family) => {
  const token = getToken() // 获取 Token
  return request.put('/updatefamily', family, {
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}

// 拿到爱好列表
export const getHobbyList = () => {
  const token = getToken() // 获取 Token
  return request.get('/hobbieList', {
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}

// 查看爱好
export const getHobby = (userid) => {
  const token = getToken() // 获取 Token
  return request.get('/hobbie', {
    params: {
      userid: userid
    },
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}
// 修改爱好
export const updataHobby = (hobby) => {
  const token = getToken() // 获取 Token
  return request.put('/updatehobbie', hobby, {
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  }
  )
}
// 删除爱好
export const deleteHobby = (id) => {
  const token = getToken() // 获取 Token
  return request.delete('/deletehobbie', {
    data: { id: id },
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}

// 添加爱好
export const addhobby = (hobby) => {
  const token = getToken() // 获取 Token
  return request.post('/addhobbie', hobby, {
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  }
  )
}

// 修改密码
export const changePassword = (userid, old_password, new_password, confirm_password) => {
  const token = getToken() // 获取 Token

  return request.patch('/updatepassword', {
    userid,
    old_password,
    new_password,
    confirm_password
  }, {
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}
