<template>
    <div class="app">
        <h2 class="title">学生生涯规划系统管理员界面</h2>
        <el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
            background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" >
            <el-menu-item index="/student">学生信息展示</el-menu-item>
            <el-menu-item index="/plan">规划信息展示</el-menu-item>
            <el-menu-item index="/admininfo">个人中心</el-menu-item>
        </el-menu>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
  name: 'userView',
  data () {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    }
  }

}
</script>

<style  scoped>
.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ffffff;
    padding: 20px;
    margin-left:20px;
    margin-right:20px;
}

.title {
    text-align: left;
    margin-bottom: 20px;
}

.el-menu-demo {
    height: 50px;
    width: 100%;
}
.el-menu-item{
    font-size: 20px;
    width: 33.3333%;
}
.content {
    flex: 1;
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;

}
</style>
