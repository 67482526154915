<template>
<nguihua></nguihua>
</template>

<script>
import nguihua from '@/components/nguihua.vue'
export default {
  name: 'guihuaView',
  components: { nguihua }
}
</script>

<style>

</style>
