<template>
  <div>
    <div style="display: flex; justify-content: center;">
      <el-input v-model="input" placeholder="请输入学号" style="width: 700px; "></el-input>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
      <el-table :data="studentinfo" style="width: 100%" >
          <el-table-column prop="userid"  label="学号"  width="auto"></el-table-column>
          <el-table-column prop="specialty"  label="专业"  width="auto"></el-table-column>
          <el-table-column prop="sex"  label="性别" width="auto">
            <template slot-scope="scope">
              {{ scope.row.sex === 0 ? '男' : '女' }}
            </template>
          </el-table-column>
          <el-table-column prop="age"  label="年龄" width="auto"></el-table-column>
          <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button  @click="openchangeDialog(scope.row)  " type="primary" size="small">修改</el-button>
              </template>
          </el-table-column>
      </el-table>
      <template>
        <div class="example-pagination-block" style="display: flex; justify-content: center; margin-top: 10px;">
      <el-pagination background layout="prev, sizes,pager, next" :page-sizes="[10, 20, 30, 40]"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-size="pageSize" :total="total" />
    </div>
  </template>
          <changeuserinfo
          :userinfo="userinfo"
          :dialogVisible="dialogVisible"
          @changeUserinfo="changeUserinfo"
          @close="dialogVisible=false"></changeuserinfo>
  </div>
</template>

<script>
import changeuserinfo from '@/components/changeuserinfo'
import { getUserinfo } from '@/api/user'
import { getstudentinfo } from '@/api/admin'
export default {
  name: 'studentView',
  data () {
    return {
      studentinfo: [],
      userid: '',
      input: '',
      dialogVisible: false,
      onlystudent: [],
      pageNum: 1,
      pageSize: 10,
      total: 50,
      userinfo: {}
    }
  },
  components: {
    changeuserinfo
  },
  methods: {
    openchangeDialog (row) {
      this.userinfo = row
      console.log(this.userinfo)
      this.dialogVisible = true
    },
    async handleSizeChange (newSize) {
      const res = await getstudentinfo(this.pageNum, newSize)
      this.studentinfo = res.data.data.list
      this.pageNum = res.data.data.pageNum
      this.pageSize = res.data.data.pageSize
      this.total = res.data.data.total
    },
    async handleCurrentChange (newPage) {
      const res = await getstudentinfo(newPage)
      this.studentinfo = res.data.data.list
      this.pageNum = res.data.data.pageNum
      this.pageSize = res.data.data.pageSize
      this.total = res.data.data.total
    },
    changeUserinfo (res) {
      this.userinfo = {
        ...this.userinfo, // 保留原有的 userinfo
        sex: res.data.data.sex, // 更新性别
        specialty: res.data.data.specialty, // 更新专业
        age: res.data.data.age
      }
      const index = this.studentinfo.findIndex(student => student.userid === this.userinfo.userid)
      // 如果找到了对应的索引，就进行替换
      if (index !== -1) {
        this.$set(this.studentinfo, index, this.userinfo)
      }
    },
    async search () {
      if (this.input === '') {
        alert('请输入学号')
      } else {
        const res = await getUserinfo(this.input)
        this.studentinfo = [res.data.data]
      }
    }
  },
  async mounted () {
    const res = await getstudentinfo()
    this.studentinfo = res.data.data.list
  }

}
</script>

<style>

</style>
