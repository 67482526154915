<template>
  <div>
    <div style="display: flex; justify-content: center;">
      <el-input v-model="input" placeholder="请输入学号" style="width: 700px; "></el-input>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
      <el-table :data="guihuainfo" style="width: 100%" >
          <el-table-column prop="createdAt"  label="创建时间"  width="auto"></el-table-column>
          <el-table-column prop="updatedAt"  label="更新时间"  width="auto"></el-table-column>
          <el-table-column prop="title"  label="规划标题"  width="auto"></el-table-column>
          <el-table-column prop="project"  label="规划内容" width="500"></el-table-column>
          <el-table-column label="操作" width="auto">
              <template slot-scope="scope">
                <el-button  @click="openchangeDialog(scope.row)  " type="primary" size="small">修改</el-button>
              </template>
          </el-table-column>
      </el-table>
      <changePlan
        :dialogVisible="dialogVisible"
        :selectedRow="selectedRow"
        :isEdit="isEdit"
        @close="dialogVisible = false"
        @adminupdata="adminupdata"></changePlan>
        <template>
    <div class="example-pagination-block" style="display: flex; justify-content: center; margin-top: 10px;">
      <el-pagination background layout="prev, sizes,pager, next" :page-sizes="[10, 20, 30, 40]"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-size="pageSize" :total="total" />
    </div>
  </template>
  </div>
</template>

<script>
import changePlan from '@/components/changePlan'
import { plan } from '@/api/guihua'
import { getplan } from '@/api/admin'
export default {
  name: 'planView',
  data () {
    return {
      userid: '',
      input: '',
      pageNum: 1,
      pageSize: 10,
      total: 50,
      guihuainfo: [],
      onlystudent: [],
      selectedRow: null,
      isEdit: true,
      dialogVisible: false
    }
  },
  components: {
    changePlan
  },
  methods: {
    openchangeDialog (row) {
      this.userid = row.userid
      this.dialogVisible = true
      this.selectedRow = row
      console.log(row)
    },
    async handleSizeChange (newSize) {
      const res = await getplan(this.pageNum, newSize)
      this.guihuainfo = res.data.data.list
      this.pageNum = res.data.data.pageNum
      this.pageSize = res.data.data.pageSize
      this.total = res.data.data.total
    },
    async handleCurrentChange (newPage) {
      const res = await getplan(newPage)
      this.guihuainfo = res.data.data.list
      this.pageNum = res.data.data.pageNum
      this.pageSize = res.data.data.pageSize
      this.total = res.data.data.total
    },
    // 时间格式化
    formatDate (dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const date = new Date(dateString)
      return date.toLocaleDateString('zh-CN', options) // 使用中文格式
    },
    adminupdata (res) {
      const index = this.guihuainfo.findIndex(item => item.id === res.data.data.id)
      if (index !== -1) {
        const updatedData = {
          ...this.guihuainfo[index], // 保留原有的其他属性
          title: res.data.data.title, // 更新标题
          project: res.data.data.project, // 更新内容-
          semester: Number(res.data.data.semester), // 转化为 Number 类型
          plantype: Number(res.data.data.plantype), // 转化为 Number 类型
          updatedAt: this.formatDate(new Date()) // 使用当前日期时间来更新
        }
        this.$set(this.guihuainfo, index, updatedData)
      }
    },
    async search () {
      if (this.input === '') {
        alert('请输入学号')
      } else {
        const res = await plan(this.input)
        this.guihuainfo = res.data.data
      }
    }
  },
  async mounted () {
    const res = await getplan()
    this.guihuainfo = res.data.data.list
  }
}
</script>

<style>

</style>
