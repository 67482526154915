//轮播图
<template>
    <div class="block">
        <el-carousel trigger="click" height="1000px">
            <el-carousel-item v-for="item in imgList" :key="item.id">
                <img :src="item.url" alt="" class="small" />
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
  name: 'lunBo',
  data () {
    return {
      imgList: [{ id: 1, url: require('@/assets/banner1.jpg') },
        { id: 2, url: require('@/assets/banner2.jpg') },
        { id: 3, url: require('@/assets/banner3.jpg') }
      ]
    }
  }
}
</script>
<style scoped>
.small {
    width: 100%;
    height: 100%;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>
