import { getToken } from '@/utils/storage'
import request from '../utils/request'

export const getstudentinfo = (pageNum, pageSize) => {
  const token = getToken() // 获取 Token
  return request.get('/users', {
    params: {
      pageNum,
      pageSize
    },
    headers: {
      Authorization: `${token}`, // 设置 Authorization 头
      'Content-Type': 'application/json'
    }
  })
}

export const getplan = (pageNum, pageSize) => {
  const token = getToken() // 获取 Token
  return request.get('/TestBooks',
    {
      params: {
        pageNum,
        pageSize
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}` // 设置 Authorization 头
      }
    })
}

export const changePwd = (userid, new_password, confirm_password) => {
  const token = getToken() // 获取 Token
  return request.patch('/adminupdatepwd', {
    userid,
    new_password,
    confirm_password
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}` // 设置 Authorization 头
    }
  })
}
