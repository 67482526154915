<template>
  <div>
    <h2>修改密码</h2>
    <p>
    账号：<el-input type="username" placeholder="请输入账号" v-model="userid" style="width: 275px; margin-left: 34px;"></el-input>
  </p><p>新密码：<el-input type="password" placeholder="请输入新密码" v-model="newpwd" style="width: 275px; margin-left: 18px;"></el-input>
   </p> 确认密码：<el-input type="password" placeholder="请确认密码" v-model="cnpwd" style="width: 275px; "></el-input>
   <p>
    <el-button style="margin-left: 283px;" type="primary" @click="changepwd">确认</el-button>
    <el-button style="float: right;" type="primary" @click="edit">退出登录</el-button></p>
  </div>
</template>

<script>
import { changePwd } from '@/api/admin'
export default {
  name: 'adminView',
  data () {
    return {
      userid: '',
      newpwd: '',
      cnpwd: ''
    }
  },
  methods: {
    edit () {
      localStorage.removeItem('login_info')
      this.$router.push('/login')
    },
    async changepwd () {
      if (this.newpwd === '' || this.cnpwd === '') {
        alert('请输全密码')
      } else if (this.newpwd !== this.cnpwd) {
        alert('两次密码不一致')
      } else {
        const userid = this.userid
        const new_password = this.newpwd
        const confirm_password = this.cnpwd
        const res = await changePwd(userid, new_password, confirm_password)
        if (res.data.code === 200) {
          alert('修改成功')
          this.userid = ''
          this.newpwd = ''
          this.cnpwd = ''
          if (this.userid === 'root') {
            localStorage.removeItem('login_info')
            this.$router.push('/login')
          }
        }
      }
    }
  }
}
</script>

<style>

</style>
